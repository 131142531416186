var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"saveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"name-building"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tên tòa nhà "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tên tòa nhà","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-building","name":"nameBuilding","placeholder":"Tên tòa nhà","state":_vm.getElementState(errors)},model:{value:(_vm.dataBuilding.buildingName),callback:function ($$v) {_vm.$set(_vm.dataBuilding, "buildingName", $$v)},expression:"dataBuilding.buildingName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Thứ tự "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Thứ tự","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"order_no","type":"number","state":_vm.getElementState(errors)},model:{value:(_vm.dataBuilding.orderNo),callback:function ($$v) {_vm.$set(_vm.dataBuilding, "orderNo", $$v)},expression:"dataBuilding.orderNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Trạng thái "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trạng thái","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"status","options":_vm.statuses,"reduce":function (option) { return option.value; }},model:{value:(_vm.dataBuilding.status),callback:function ($$v) {_vm.$set(_vm.dataBuilding, "status", $$v)},expression:"dataBuilding.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-left"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid},on:{"click":_vm.handleSaveData}},[_vm._v(" Lưu ")])],1)])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }